<template>
    <div style="height: 100%;" >
        <div class="first" :style="firstShow">
            <div id="app" :style="{ backgroundImage: 'url(' + require('@/assets/bg.png') + ')' }">
                <div class="form-container" style="width: 100%;">
                    <h2>兑换码兑换</h2>
                    <div class="form">
                        <label for="code">兑换码:</label>
                        <input type="text" v-model="code">

                        <label for="password">密码:</label>
                        <input type="password" v-model="password">

                        <button @click="submitFirstForm">提交</button>
                        <button @click="selectExpress" style="margin-top: 10px;">物流查询</button>
                        <div style="font-size: 13px;font-weight: 500;margin-top: 5px;">提示：查询物流需要输入兑换码和密码</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="second" :style="secondShow">
            <div id="app" :style="{ backgroundImage: 'url(' + require('@/assets/bg1.png') + ')' }">
                <div class="form-container" style="width: 100%;">
                    <h5>{{title}}</h5>
                    <div class="form">
                        <input type="text" v-model="username" placeholder="收货人">

                        <input type="text" v-model="phone" placeholder="收货电话">

                        <input type="text" v-model="address" placeholder="详细地址">

<!--                        <input type="password" v-model="remake" placeholder="备注">-->
                        <textarea v-model="remake" placeholder="备注" rows="10"></textarea>

                        <button @click="submitSecondForm" style="margin-top: 10px;">提交</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="third" :style="thirdShow">
            <div id="app" :style="{ backgroundImage: 'url(' + require('@/assets/bg1.png') + ')' }">
                <div class="form-container" style="width: 100%;">
                    <h5>礼包卡兑换成功</h5>
                    <button @click="reback" style="margin-top: 10px;">返回首页</button>
                </div>
            </div>
        </div>
        <div class="fourth" :style="fourthShow">
            <div id="app" :style="{ backgroundImage: 'url(' + require('@/assets/bg1.png') + ')' }">
                <div>
                    <div v-if="flag">
                        <div style="font-weight: bold;font-size: 30px;color: #ac4d1f;">暂无物流信息</div>
                        <button @click="reback" style="margin-top: 10px;margin-left: 30%;">返回首页</button>
                    </div>
                    <div v-else class="form-container" style="width: 100%;">
                        <el-timeline>
                            <el-timeline-item
                                    v-for="(activity, index) in activities"
                                    :key="index"
                                    :icon="activity.icon"
                                    :type="activity.type"
                                    :color="activity.color"
                                    :size="activity.size"
                                    :timestamp="activity.timestamp">
                                {{activity.content}}
                            </el-timeline-item>
                        </el-timeline>

                        <button @click="reback" style="margin-top: 10px;margin-right: 5%;width: 70%;">返回首页</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },
        data() {
            return {
                // baseUrl:'http://192.168.3.6:8080',
                baseUrl:'https://www.hongshengguoye.cn:8080',
                show: "display: block",
                none: "display: none",
                firstShow: "display: block",
                secondShow: "display: none",
                thirdShow: "display: none",
                fourthShow: "display: none",
                /* first */
                title:'',
                cardNo: '',
                code: '',
                password: '',
                url: {
                    usedGiftCard: "/jeecg-boot/wechat/mp/h5/usedGiftCard",
                    usedGiftCardReceiver: "/jeecg-boot/wechat/mp/h5/usedGiftCardReceiver",
                    getExpressInfo: "/jeecg-boot/wechat/mp/h5/getExpressInfo",
                },

                /* second */
                username: '',
                phone: '',
                address: '',
                remake: '',
                /* third */

                /* fourth */
                flag: true,
                activities: []
            }
        },
        created() {
            // this.selectPage(4)
            // this.getExpressInfo()
        },
        methods:{
            selectPage(index) {
              if (index == 1) {
                  this.firstShow = "display: block"
                  this.secondShow = "display: none"
                  this.thirdShow = "display: none"
                  this.fourthShow = "display: none"
              } else if (index == 2) {
                  this.firstShow = "display: none"
                  this.secondShow = "display: block"
                  this.thirdShow = "display: none"
                  this.fourthShow = "display: none"
              } else if (index == 3) {
                  this.firstShow = "display: none"
                  this.secondShow = "display: none"
                  this.thirdShow = "display: block"
                  this.fourthShow = "display: none"
              } else if (index == 4) {
                  this.firstShow = "display: none"
                  this.secondShow = "display: none"
                  this.thirdShow = "display: none"
                  this.fourthShow = "display: block"
              }
            },
            /* first */
            submitFirstForm() {
                let that = this

                if (this.code == "") {
                    this.$message({
                        message: "兑换码为空",
                        center: true,
                        type: 'error', // 提示框类型，可以是 success、warning、info、error
                    });
                    return;
                }
                if (this.password == "") {
                    this.$message({
                        message: "密码为空",
                        center: true,
                        type: 'error', // 提示框类型，可以是 success、warning、info、error
                    });
                    return;
                }
                var url = this.baseUrl + this.url.usedGiftCard + "?cardCode="+this.code+"&cardPassword=" + this.password
                console.log(url)
                axios.get(url)
                    .then(response => {
                        if (response.data.code == 200) {
                            that.title = response.data.result.title
                            that.cardNo = response.data.result.cardNo
                            that.selectPage(2)
                        } else {
                            that.$message({
                                message: response.data.result,
                                center: true,
                                type: 'warning', // 提示框类型，可以是 success、warning、info、error
                            });
                        }
                    })
                    .catch(error => {
                        // 请求失败，处理错误信息
                        that.$message({
                            message: "兑换失败",
                            center: true,
                            type: 'error', // 提示框类型，可以是 success、warning、info、error
                        });
                    });
            },
            selectExpress() {
                if (this.code == "") {
                    this.$message({
                        message: "兑换码为空",
                        center: true,
                        type: 'error', // 提示框类型，可以是 success、warning、info、error
                    });
                    return;
                }
                if (this.password == "") {
                    this.$message({
                        message: "密码为空",
                        center: true,
                        type: 'error', // 提示框类型，可以是 success、warning、info、error
                    });
                    return;
                }
                this.getExpressInfo()
            },
            /* second */
            submitSecondForm() {
                let that = this
                console.log(that.cardNo)
                var url = this.baseUrl + this.url.usedGiftCardReceiver + "?cardNo="+this.cardNo + "&username=" + this.username
                    + "&phone=" + this.phone + "&address=" + this.address + "&remake=" + this.remake
                axios.get(url)
                    .then(response => {
                        if (response.data.code == 200) {
                            that.selectPage(3)
                        } else {
                            that.$message({
                                message: response.data.message,
                                center: true,
                                type: 'warning', // 提示框类型，可以是 success、warning、info、error
                            });
                        }
                    })
                    .catch(error => {
                        // 请求失败，处理错误信息
                        console.error('Error fetching data:', error);
                    });
            },
            /* third */
            reback() {
              this.selectPage(1)
              this.code = ""
              this.password = ""
            },
            /* fourth */
            getExpressInfo() {
                var url = this.baseUrl + this.url.getExpressInfo + "?cardCode=" + this.code
                let that = this
                axios.get(url)
                    .then(response => {
                        if (response.data.code == 200) {
                            var result = response.data.result;
                            that.flag = true
                            that.selectPage(4)
                            if (result != null) {
                                that.flag = false
                                for (let i = 0; i < result.length; i++) {
                                    if (i == 0) {
                                        that.activities.push({"content":result[i].node_desc,
                                            "timestamp":result[i].node_time,
                                            "size": 'large',"type":"success "})
                                    } else {
                                        that.activities.push({"content":result[i].node_desc,
                                            "timestamp":result[i].node_time,
                                            "size": 'large'})
                                    }

                                }
                            }

                        } else {
                            that.$message({
                                message: response.data.message,
                                center: true,
                                type: 'warning', // 提示框类型，可以是 success、warning、info、error
                            });
                        }
                    })
                    .catch(error => {
                        // 请求失败，处理错误信息
                        console.error('Error fetching data:', error);
                    });
            }
        }
    }
</script>

<style scoped>
    #app {
        height: 100vh;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-container {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px;
        border-radius: 8px;
        text-align: center;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    label {
        margin-bottom: 5px;
    }

    input {
        margin-bottom: 10px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        padding: 10px;
        background-color: #007BFF;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #0056b3;
    }
</style>

<template>

    <Index class="container" style="height: 100%;" msg="aaaa"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Index from "@/Index.vue";

export default {
  name: 'App',
  components: {
    HelloWorld,Index
  }
}
</script>

<style>
.container {
    margin: 0;
    padding: 0;
}
.el-timeline-item__content {
    color: #303133;
    width: 90%;
}
</style>
